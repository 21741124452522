<template>
  <div v-show="mfcSelected !== 0" class="save">
    <nut-cell :title="'面粉厂名字:' + mfcSelectName" @click="show = true" is-link/>
    <nut-picker v-model:visible="show" :columns="mfcList" title="面粉厂选择" @change="changeMfc"/>
    <div class="surplusFlour">
      当前面粉剩余:{{ surplusFlour/1000 *2 }}斤
    </div>
    
    <div style="margin-top: .5rem">
      <nut-tabs v-model="tableValue" >
        <nut-tabpane title="支取">
          <nut-table :columns="columns" :data="list" bordered v-if=" tableValue == 0 "></nut-table>
        </nut-tabpane>
        <nut-tabpane title="预存">
          <nut-table :columns="columns" :data="list" bordered v-if=" tableValue == 1 "></nut-table>
        </nut-tabpane>
      </nut-tabs>
      <nut-pagination class="pageBottom"  v-model="pageCurrent" v-show="pageTotal" :page-count="pageTotal" mode="simple" @change="pageChange" />
    </div>
  </div>
</template>

<script>
import {ref, watch,} from "vue";
import { useOptionsConfig } from "../../hooks/optionsConfig";
import * as saveApi from "../../api/save";
import { useTable } from "./flourRecord";

export default {
  setup() {
    const show = ref(false);
    const tableValue = ref(0);
    const surplusFlour = ref(0);
    const mfcSelectName = ref("");
    const { mfcList, mfcSelected } = useOptionsConfig();
    const { getFlourRecord, columns, list, pageTotal, pageCurrent } = useTable();
    const changeMfc = ({ selectedValue }) => {
      mfcSelected.value = selectedValue[0];
    };
    const getSurplusFlour = (mfcId) => {
      saveApi.getSurplusFlour({ mfc_id: mfcId }).then((res) => {
        surplusFlour.value = res.data.surplus_flour;
      });
    };
    //1存  2取  1.小麦兑换 2.货币购买 3.积分兑换 4.小麦预存 5.面粉支取
    const getType = (tableValue) => {
      if( tableValue == 0 ){
        return 5;
      }else{
        return 4;
      }
    };

    const pageChange = (value) => {
      getFlourRecord(mfcSelected.value, getType(tableValue.value), value);
    };
    const getMfcNameByMfcId = (mfcId) => {
      var mfcName = "";
      var mfcListLen = mfcList.value.length;
      for( var i=0;i<mfcListLen;i++ ){
        if( mfcList.value[i].value == mfcId ){
          mfcName = mfcList.value[i].text;
        }
      }
      return mfcName;
    };
    watch(mfcSelected, (mfcId) => {
      mfcSelectName.value = getMfcNameByMfcId(mfcId);
      getSurplusFlour(mfcId);
      getFlourRecord(mfcId, getType(tableValue.value),1);
    });
    watch(tableValue, (table) => {
      getFlourRecord(mfcSelected.value, getType(table),1);
    });
    return {
      pageChange,
      changeMfc,
      show,
      mfcList,
      mfcSelected,
      mfcSelectName,
      tableValue,
      surplusFlour,
      columns,
      list,
      pageTotal,
      pageCurrent,
    };
  },
};
</script>

<style scoped lang="scss">
.save {
  .mfcList {
    width: 60%;
    height: 2rem;
    border: 1px solid #a9a9a9;
  }
  .pointsShow {
    margin-top: 0.8rem;
  }

  :deep(.nut-cell__title) {
    align-items: flex-start;
    font-size: 15px;
    font-weight: 500;
  }
  :deep(.nut-cell__value) {
    font-size: 15px;
    font-weight: 500;
    color: #666 !important;
  }
  .nut-pagination {
    margin-left:20px;
  }
  .surplusFlour{
    padding: 0.2rem 0.8rem;
    background-color: #ffffff;
    height: 2rem;
  }
}
</style>
