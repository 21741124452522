import { ref } from "vue";
import * as saveApi from "../../api/save";
import Toast from "@nutui/nutui";
import dayjs from "dayjs";

const columns = [
  {
    title: "时间",
    key: "time",
  },
  {
    title: "存取面粉",
    key: "desc",
  },
];

export const useTable = () => {
  const list = ref([]);
  const pageTotal = ref(0);
  const pageCurrent = ref(0);
  const getFlourRecord = (mfcId, type, page) => {
    //1存  2取  1.小麦兑换 2.货币购买 3.积分兑换 4.小麦预存 5.面粉支取
    saveApi.getFlourRecord({mfc_id: mfcId,type: type,page:page,page_size : 20}).then(
      ( res) => {
        if( res.code ==0 ){
          //存取免费记录
          pageTotal.value = res.data.page.page_total;
          pageCurrent.value = res.data.page.page_index;
          var data = res.data.list;
          var dataLength = data.length;
          var recordList = [];
          for (var i = 0 ; i < dataLength;i++ ){
            var time = dayjs(data[i]["add_time"] * 1000).format("YYYY-MM-DD HH:mm");
            var desc = "";
            if(data[i]["order_type"] == 4){
              desc = "预存" + data[i]['order_detail'][0]["goods_amount"]/1000 *2 + "斤";
            }else if(data[i]["order_type"] == 5){
              desc = "取面" + data[i]['order_detail'][0]["goods_amount"]/1000 *2 + "斤";
            }
            recordList.push({time: time, desc:desc});
          }
          list.value = recordList;
        }else{
          Toast.fail(res.message);
        }
    }
    );
  };
  return {
    getFlourRecord,
    columns,
    list,
    pageTotal,
    pageCurrent,
  };
};
